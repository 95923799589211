import { accountingPermissions } from "./RequiredPermissions/accountingFirm";
import useCheckPermissions from "./useCheckPermissions";

export default function useAccountingFirmPermissions() {
  const canDisplayAccountingFirms = useCheckPermissions(accountingPermissions.displayList);
  const canCreateAccountingFirm = useCheckPermissions(accountingPermissions.create);
  const canUpdateAccountingFirm = useCheckPermissions(accountingPermissions.update);
  const canViewAccountingFirm = useCheckPermissions(accountingPermissions.view);
  const canDisplayAccountingFirmSettings = useCheckPermissions(
    accountingPermissions.displaySettings,
  );
  const canUpdateAccountingFirmSettings = useCheckPermissions(accountingPermissions.updateSettings);
  const canViewAccountingFirmSettings = useCheckPermissions(accountingPermissions.viewSettings);
  const canDisplayPlatforms = useCheckPermissions(accountingPermissions.displayPlatform);
  const canCreatePlatforms = useCheckPermissions(accountingPermissions.createPlatform);
  const canUpdatePlatforms = useCheckPermissions(accountingPermissions.updatePlatform);
  const canDeletePlatforms = useCheckPermissions(accountingPermissions.deletePlatform);
  const canDisplayServices = useCheckPermissions(accountingPermissions.displayServices);
  const canCreateSubscriptions = useCheckPermissions(accountingPermissions.createSubscriptions);
  const canUpdateSubscriptions = useCheckPermissions(accountingPermissions.updateSubscriptions);
  const canDisplayAccountingFirmAddress = useCheckPermissions(
    accountingPermissions.displayAddresses,
  );
  const canDisplayAccountingFirmContacts = useCheckPermissions(
    accountingPermissions.displayContacts,
  );
  const canUpdateSsiType = useCheckPermissions(accountingPermissions.updateSsiType);

  const canCreateBankAccount = useCheckPermissions(accountingPermissions.createBankAccount);
  const canUpdateBankAccount = useCheckPermissions(accountingPermissions.updateBankAccount);
  const canDeleteBankAccount = useCheckPermissions(accountingPermissions.deleteBankAccount);

  return {
    canDisplayAccountingFirms,
    canCreateAccountingFirm,
    canUpdateAccountingFirm,
    canViewAccountingFirm,
    canDisplayAccountingFirmSettings,
    canUpdateAccountingFirmSettings,
    canViewAccountingFirmSettings,
    canDisplayPlatforms,
    canCreatePlatforms,
    canUpdatePlatforms,
    canDeletePlatforms,
    canDisplayServices,
    canCreateSubscriptions,
    canUpdateSubscriptions,
    canDisplayAccountingFirmAddress,
    canDisplayAccountingFirmContacts,
    canUpdateSsiType,
    canCreateBankAccount,
    canUpdateBankAccount,
    canDeleteBankAccount,
  };
}
